.about {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

//About Info
.about-info {
  padding-bottom: 80px;
  padding-top: 40px;
  display: flex;
  align-items: center;
}

//About Representatives
.about-representatives {
  padding-top: 80px;
  padding-bottom: 80px;

  &-hr {
    background-color: black;
    height: 2px;
    border: none;
    @include cta-shadow-elevation-high();

    @media (min-width: 1400px) {
      width: 1092px;
    }
  }
}

.about {
  &-leaders-container {
    display: flex;
    flex-direction: column-reverse;
    text-align: center;
    justify-content: flex-end;

    @media (min-width: 992px) {
      flex-direction: row-reverse;
      gap: 90.79px;
    }

    @media (min-width: 1200px) {
      gap: 0px;
    }
  }

  &-members-container,
  &-prefix-container {
    display: grid;
    grid-template-columns: repeat(1, auto);

    @media (min-width: 992px) {
      grid-template-columns: repeat(2, auto);
    }

    @media (min-width: 1200px) {
      grid-template-columns: repeat(3, auto);
    }
  }
}

.about-leaders,
.about-members,
.about-prefix {
  margin-top: 40px;

  &-container > * {
    display: flex;
    flex-direction: row;
    padding: 20px;
    gap: 30px;
    text-align: start;
    align-items: center;

    @media (min-width: 992px) {
      width: 365.21px;
    }
  }

  &-container-text {
    margin-left: -10px;
  }

  & img {
    width: 130px;
    border-radius: 50%;
    @include shadow-elevation-high();
  }

  & h4 {
    font-size: 25px;
    text-align: center;
    margin-bottom: 20px;
  }

  & h5 {
    font-size: 20px;
  }

  & p {
    font-size: 15px;
    margin-top: 0px;
    margin-bottom: 0px;
  }
}

//About Join
.about-join {
  margin: 100px 0px;

  &-container-text {
    margin-bottom: 30px;
    margin-top: 30px;
  }

  &-container-buttons {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (min-width: 768px) {
      flex-direction: row;
      justify-content: flex-end;
    }
  }
}
