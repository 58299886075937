.heading-regular {
  font-family: CoopGroteskRegular, sans-serif;
}

// Intro info
.home-info {
  & > *:nth-child(even) {
    // Will give every second section a background color, and flex reverse
    @media (min-width: 992px) {
      flex-direction: row-reverse;
    }
  }

  & > * {
    display: flex;
    flex-direction: column;
    padding: 30px 0px;

    @media (min-width: 768px) {
      align-items: center;
    }

    @media (min-width: 992px) {
      flex-direction: row;
      padding: 100px 0px;
    }

    @media (min-width: 1200px) {
      flex-direction: row;
    }
  }

  &-container {
    justify-content: center;

    @media (min-width: 992px) {
      margin-bottom: 0px;
    }

    & img {
      width: 100%;
      margin-right: 20px;
      border-radius: 5%;
      @include shadow-elevation-high();

      @media (min-width: 768px) {
        width: 80%;
      }

      @media (min-width: 992px) {
        width: 38%;
      }

      @media (min-width: 2000px) {
        width: 43%;
      }
    }

    &-text {
      width: 100%;
      margin: 40px auto;

      @media (min-width: 992px) {
        width: 40%;
        margin: 50px;
      }
    }
  }
}

// Siste nytt
.latest-news-section {
  padding: 20px;
  border-radius: 5px;
  transition: 0.15s ease-in-out;

  & > * {
    margin: 0px 40px 40px 40px;
  }

  &:hover {
    @include shadow-elevation-high;
    transition: 0.15s ease-in-out;
  }

  & > a {
    text-decoration: none;
    color: $text-color;
    transition: 0.15s ease-in-out;
    margin: 20px 0px;

    &:hover {
      color: $accent-color;
    }
  }
}

.latest-news {
  display: flex;
  flex-direction: column;
  max-width: 300px;
  text-decoration: none;
  color: inherit;

  & img {
    width: 300px;
    height: 200px;
    object-fit: cover;
    @include shadow-elevation-medium();
    border-radius: 5px;
  }

  &-container {
    &-creator {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;
      font-size: 0.9rem;
      & span,
      &-date {
        font-weight: 600;
        color: $accent-color;
      }

      &-date {
        white-space: nowrap;
        flex-basis: 50%;
      }

      &-author {
        flex-basis: 50%;
        text-align: end;
      }

      & h5 {
        font-size: 1.05rem;
      }

      & p {
        font-size: 0.9rem;
      }
    }
  }
}

.news-page-card {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  gap: 100px;
}
