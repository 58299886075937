// Fonts
@font-face {
  font-family: "CoopGroteskRegular";
  src: url(../../fonts/Coop-Grotesk-Regular.otf);
}

@font-face {
  font-family: "CoopGroteskBold";
  src: url(../../fonts/Coop-Grotesk-Bold.otf);
}

@font-face {
  font-family: "CoopGroteskItalic";
  src: url(../../fonts/Coop-Grotesk-Italic.otf);
}

@font-face {
  font-family: "CoopGroteskBoldCondensed";
  src: url(../../fonts/Coop-Grotesk-Bold-Condensed.otf);
}

// Body
body {
  box-sizing: border-box;
  font-family: "Open sans", "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  color: $text-color;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: CoopGroteskBold, "Open sans", "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

h1 {
  margin: 0;
}

p {
  line-height: 2.1;
  font-size: 1.05rem;
}

main {
  margin-bottom: 60px;
}

main > *:last-child {
  @media (min-width: 992px) {
    margin-bottom: 150px;
  }
}

// Icons
.fa-newspaper,
.fa-circle-info,
.fa-gift,
.fa-person-hiking,
.fa-envelope,
.fa-house-chimney,
.fa-pen,
.fa-download,
.fa-candy-cane,
.fa-arrow-up {
  font-size: 1.4rem;
  margin: 0px 7px;
}

.fa-person-hiking-flipped {
  transform: scaleX(-1);
  position: absolute;
  bottom: 0;
  right: 100px;
  font-size: 2rem;
  color: $dark-background;
}

// CTA
.cta {
  background-color: $accent-color;
  padding: 0.8rem 1.8rem;
  color: $primary-color;
  text-decoration: none;
  font-weight: 600;
  border-radius: 3rem;
  border: 2px solid $accent-color;
  margin: 20px auto;

  transition: 0.15s ease-in-out;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
}

.cta-form {
  margin: 15px 0px;
  width: 100%;
}

.cta-standard {
  @extend .cta;
  @include cta-shadow-elevation-medium;

  &:hover {
    color: $primary-color;
    @include cta-shadow-elevation-high;
    transition: 0.15s ease-in-out;
  }
}

.cta-disabled:disabled {
  box-shadow: none;
}

.cta-alt {
  @extend .cta;
  @include cta-shadow-elevation-medium;
  background-color: $primary-color;
  color: $accent-color;
  transition: 0.15s ease-in-out;

  &:hover {
    color: $primary-color;
    background-color: $accent-color;
    @include cta-shadow-elevation-high;
    transition: 0.15s ease-in-out;
  }

  &--width {
    width: 203.7px;
  }
}

.cta-about {
  margin: 20px 10px;
}

.news-link {
  text-decoration: none;
  color: $text-color;
  padding: 15px;
  transition: 0.15s ease-in-out;
  margin: 20px 0px;

  &:hover {
    color: $accent-color;
    @include shadow-elevation-high();
    transition: 0.15s ease-in-out;
    border-radius: 5px;
  }
}

button:disabled {
  background-color: lightgray;
  border-color: lightgray;
  box-shadow: none;
}

.toTop {
  margin-top: 100px;
  display: block;

  @media (min-width: 1400px) {
    display: none;
  }
}

// Containers

.nav-container {
  padding: 0;
  @media (min-width: 992px) {
    margin-left: 0;
  }
}

// Wrappers, sets footer to bottom
#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

#mountains {
  margin-top: auto;
}

// Loader and error

.spinner-border {
  min-height: 100px !important;
  width: 100px;
  font-size: 2rem;
  color: #003366;
  margin: 50px auto;
  display: flex;
}

.alert {
  min-height: 100px !important;
  justify-content: center;
  margin: 20px 0px;
}

.empty-array {
  text-align: center;
  margin: 50px;
}

.text-loader {
  margin: 30px;
  display: block !important;
}

// Forms
.modal-form {
  & form {
    width: 100% !important;
  }
}

.modal-content {
  padding: 0px;
  @media (min-width: 1000px) {
    padding: 50px;
  }
}

#hidden-form {
  display: none;
}

// Toasts
.toast {
  border: none;
  min-width: 15%;
  height: 120px;
  z-index: 4000;
  right: 20px;
  bottom: 50px;

  &-body {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-size: 1rem;
    margin-top: 5px;
    background-color: #fff;
  }

  @media (min-width: 576px) {
    position: fixed;
  }

  &-success {
    &-header {
      background-color: #47f764;
      color: darkgreen;
    }

    & strong {
      color: darkgreen !important;
    }
  }

  &-error {
    &-header {
      background-color: #ff244c;
      color: $primary-color;
    }

    & strong {
      color: $primary-color !important;
    }
  }
}

.fa-circle-exclamation {
  color: #ff355b;
  font-size: 1.9rem;
}

.fa-circle-check {
  color: #47f764;
  font-size: 1.9rem;
}

.fa-xmark {
  margin-left: 10px;
  font-size: 1.3rem;
}

.capt-center {
  margin: 20px auto;
}
