// Variable images based on season
.spring-image {
  background-image: url("../../images/spring.jpg");
}

.summer-image {
  background-image: url("../../images/summer.jpg");
}

.autumn-image {
  background-image: url("../../images/autumn.jpg");
}

.winter-image {
  background-image: url("../../images/winter.jpg");
}

// Home banner
.home-banner {
  margin-bottom: 60px;
}

.home-image {
  background-repeat: no-repeat;
  background-size: cover;
  height: 35vh;
  background-position: center;
  position: relative;
  overflow: hidden;
  @include shadow-elevation-high();
  margin-bottom: 50px;

  @media (min-width: 992px) {
    height: 92vh;
  }

  &-overlay {
    bottom: 140px;
    right: 100px;
    max-width: 600px;
    z-index: 2;
    margin: 0px auto;

    @media (min-width: 992px) {
      position: absolute;
    }

    @media (min-width: 2000px) {
      bottom: 300px;
    }

    & h1 {
      font-size: 1.4rem;
    }

    &--ctas {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      margin-top: 10px;

      @media (max-width: 768px) {
        & a {
          width: 100%;
          text-align: center;
        }
      }
    }
  }

  @media (min-width: 992px) {
    &::after {
      content: "";
      position: absolute;
      right: 0;
      bottom: 0;
      width: 50vw;
      height: 70vh;
      // border-bottom: 950px solid $primary-color;
      border-bottom: 1150px solid $primary-color;
      // border-left: 1500px solid transparent;
      border-left: 1180px solid transparent;
      opacity: 0.7;

      @media (min-width: 2000px) {
        border-bottom: 1170px solid #f2f2f2;
        border-left: 1600px solid transparent;
      }
    }
  }
}

// Other banners
.banner-image {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  height: 25vh;
  background-position: center;
  overflow: hidden;
  @include shadow-elevation-high();
  margin-bottom: 50px;

  @media (min-width: 992px) {
    height: 63vh;
  }
}

.banner-headings {
  position: absolute;
  left: 50%;
  top: 50%;
  text-align: center;
  // Half width of element | negativ value
  margin-left: -175px;
  // Half height of element | negativ value
  margin-top: -48.8px;

  & > h1:first-child {
    display: none;
    background-color: $accent-color;
    width: fit-content;
    padding: 15px;
    color: $primary-color;
    border-radius: 5px 5px 0px 0px;
    width: 300px;
    font-size: 1.5rem;
    @include cta-shadow-elevation-high;

    @media (min-width: 992px) {
      display: block;
    }
  }

  & > h1:last-child {
    display: none;
    background-color: $primary-color;
    width: fit-content;
    padding: 15px;
    border-radius: 0px 0px 5px 5px;
    margin-left: 100px;
    width: 300px;
    font-size: 1.5rem;
    @include shadow-elevation-high();

    @media (min-width: 768px) {
      display: block;
    }
  }
}
