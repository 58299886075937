.rental {
  display: flex;
  flex-direction: column;
  justify-content: center;

  & > * {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 400px;
    margin: 40px 0px;
  }

  &-with-background {
    padding: 60px 0px;
  }
}

.rental-image img {
  width: 100%;
  height: auto;
  border-radius: 5px;
  @include shadow-elevation-high;
}
