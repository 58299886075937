//Grant Info

.grant-info {
  padding-bottom: 40px;
}

//Grant Examples
.grant-examples {
  padding-top: 80px;
  padding-bottom: 80px;

  & > * {
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  &-container {
    display: grid;
    grid-template-columns: repeat(1, auto);
    margin-bottom: 40px;

    @media (min-width: 800px) {
      grid-template-columns: repeat(2, auto);
    }

    @media (min-width: 1000px) {
      grid-template-columns: repeat(3, auto);
    }
  }

  &-container p {
    margin-bottom: 0px;
    font-size: 18px;
  }

  &-container > * {
    padding: 20px;
  }

  & img {
    width: 250px;
    height: 250px;
    object-fit: cover;
    border-radius: 5px;
    @include shadow-elevation-high();
  }

  &-container-buttons {
    text-align: center;

    & p {
      font-size: 17px;
    }
  }
}

.grant-examples-submisson {
  margin-top: 100px;
}

//Grant Submission
.grant-submission {
  padding-top: 40px;

  &-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 50px 0px;

    & form {
      width: auto;
      max-width: 600px;
    }
  }

  &-download {
    margin-top: 40px;
  }
}

.grant-submission-form > #grant-form-mail-input {
  width: 100%;
}
