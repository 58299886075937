.news-page-intro {
  position: relative;

  &-search {
    @media (min-width: 992px) {
      & input {
        width: 500px;
      }
    }
    & input {
      padding: 10px;
      border-radius: 5px;
      border: 2px solid $accent-color;
    }
  }
}

.news-h2 h2 {
  margin-top: 100px;
}

.ml-1 {
  margin-left: 0px;
}

@media (min-width: 992px) {
  .ml-1 {
    margin-left: 20px;
  }
}

.news-page-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.news-content-container {
  overflow: hidden;
  max-height: 115px;
  // Below code adds three dots when text overflows
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;

  @media (min-width: 768px) {
    max-height: 116px;
  }

  @media (min-width: 992px) {
    max-height: 120px;
  }

  &-read-more {
    text-align: end;
    font-size: 1.1rem;
    font-weight: 600;
    color: $accent-color;
    margin-right: 10px;
  }
}

.news {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0px;

  & img {
    width: 100%;
    border-radius: 10px;
    object-fit: cover;
  }
  @media (min-width: 992px) {
    flex-direction: row;

    & img {
      width: 320px;
      height: 213.33px;
    }
  }

  &-container h3 {
    font-size: 1.42rem;
  }
}

// Specific page
.cta-center {
  margin: 40px auto 60px auto;
}

.news-specific {
  &-details {
    & div:first-of-type {
      display: flex;

      & p {
        margin-right: 20px;
        color: $accent-color;
      }
    }

    &-extra {
      & h3 {
        margin-bottom: 20px;
      }
    }
  }
}
