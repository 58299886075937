header {
  z-index: 1000;
}

.navbar {
  padding: 15px;
  justify-content: center;

  &-toggler {
    border: none;

    &:focus {
      box-shadow: none;
    }
  }

  &-brand-image img {
    width: 70px;

    @media (min-width: 992px) {
      margin-left: auto;
      width: 100px;
    }
  }

  &-brand {
    color: inherit;
    font-family: CoopGroteskBold, "Open sans", "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-size: 1.2rem;

    &:hover {
      color: inherit;
    }

    @media (min-width: 992px) {
      font-size: 1.1rem;
      margin-left: 10px;
    }
  }

  &-collapse {
    flex-grow: 0;
  }
}

.brand-logo {
  &-desktop {
    display: none;
    @media (min-width: 992px) {
      margin-left: auto;
      display: block;
    }
  }

  &-mobile {
    display: none;
    @media (max-width: 992px) {
      display: block;
      margin: 20px;
      text-align: center;
    }
  }
}

.brand-logo-image {
  width: 100px;
  // margin-bottom: 20px;
  @media (min-width: 992px) {
    margin-left: auto;
    margin-bottom: 0;
  }
}

.nav-link {
  color: $accent-color;
  font-family: CoopGroteskBold, "Open sans", "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  margin: 0px 10px;
  display: flex;
  align-items: center;

  &:hover {
    color: lighten($color: $accent-color, $amount: 30);
  }

  &:focus {
    color: $accent-color;
  }

  @media (max-width: 992px) {
    font-size: 1.2rem;
  }
}

.navbar-nav .show > .nav-link {
  color: $accent-color;
}

.navbar-nav .nav-link.active {
  color: lighten($color: $accent-color, $amount: 30);
}

#avoid-active {
  color: $accent-color;
}

.desktop {
  display: none;

  @media (min-width: 992px) {
    display: block;
  }
}

.mobile {
  @media (min-width: 992px) {
    display: none;
  }
}
