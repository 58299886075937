.contact,
.grant {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  width: 100%;
  gap: 100px;
  flex-wrap: wrap;

  &-form-flex {
    display: flex;
    flex-direction: column !important;
    gap: 0px;

    @media (min-width: 992px) {
      flex-direction: row !important;
      gap: 20px;
    }
    & > * {
      flex: 1;
    }
  }
}

#contact-form,
.grant-submission-form {
  & form {
    display: flex;
    flex-direction: column;
    width: 300px;

    @media (min-width: 576px) {
      width: 400px;
    }

    & > * {
      display: flex;
      flex-direction: column;
    }

    & select {
      // These styles makes sure the mobile browsers webkits don't style the select
      cursor: pointer;
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      background-image: url(../../images/chevron-down-solid.svg);
      background-repeat: no-repeat;
      background-position: right 0.7em top 50%, 0 0;
      background-size: 0.85em;
      color: $text-color;
      background-color: #ffffff;
    }

    & select,
    input,
    textarea {
      width: 100%;
      padding: 10px 8px;
      border-radius: 5px;
      border: 1px solid $dark-background;
      margin-bottom: 20px;
      @include shadow-elevation-medium();

      &:hover {
        &::placeholder {
          padding: 0px 5px;
          transition: 0.15s ease-in-out;
        }
      }

      &:focus-visible {
        outline-color: $dark-background;
      }
    }

    & span {
      font-size: 1.3rem;
      color: red;
    }
  }
}

.vertical-line {
  display: none;
}

@media (min-width: 1200px) {
  .vertical-line {
    display: flex;
    border-left: 2px solid $accent-color;
  }
}

#FaQ {
  width: 350px;

  & .accordion {
    border-radius: 5px;

    &-body {
      line-height: 1.7;
    }
  }

  @media (min-width: 992px) {
    width: 500px;
  }

  .accordion-button {
    color: $text-color;
    font-family: "CoopGroteskRegular";

    &:not(.collapsed) {
      background-color: $accent-color;
      color: $primary-color;
    }

    &:focus {
      border-color: none;
      box-shadow: none;
    }
  }
}
