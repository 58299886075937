#mountains {
  position: relative;
  height: 300px;
  background-color: none;

  @media (min-width: 992px) {
    height: 450px;
  }

  @media (max-width: 992px) {
    background-color: transparent !important;
    .sun {
      display: none;
    }
  }
}

.mountain-floor {
  padding: 50px;
  width: 100%;
  background-color: $dark-background;
  z-index: 2000;
}

.footer {
  padding: 20px;
  width: 100%;
  background-color: $dark-background-darker;
  display: flex;
  justify-content: center;
  color: $primary-color;
  font-family: CoopGroteskRegular;
  font-size: 1rem;
  z-index: 3;
}

.bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.mountain,
.mountain-two,
.mountain-three {
  position: absolute;
  bottom: 0;
  z-index: 1;
}

.mountain {
  left: 143px;
  border-left: 217px solid transparent;
  border-right: 217px solid transparent;
  border-bottom: 260px solid $dark-background;
  display: none;

  @media (min-width: 768px) {
    display: flex;
  }
}

.mountain-two {
  left: 533px;
  bottom: -15px;
  opacity: 0.5;
  z-index: 0;
  border-left: 107px solid transparent;
  border-right: 107px solid transparent;
  border-bottom: 130px solid $dark-background;
  display: none;

  @media (min-width: 768px) {
    display: flex;
  }
}
.mountain-three {
  left: -60px;
  bottom: -10px;
  opacity: 0.7;
  z-index: 5;
  border-left: 150px solid transparent;
  border-right: 150px solid transparent;
  border-bottom: 180px solid $dark-background;
}
.mountain-top {
  position: absolute;
  right: -65px;
  border-left: 65px solid transparent;
  border-right: 65px solid transparent;
  border-bottom: 77px solid #f2f2f2;
  z-index: 2;
}
.mountain-cap-1,
.mountain-cap-2,
.mountain-cap-3 {
  position: absolute;
  top: 70px;
  border-left: 25px solid transparent;
  border-right: 25px solid transparent;
  border-top: 25px solid #f2f2f2;
}
.mountain-cap-1 {
  left: -55px;
}
.mountain-cap-2 {
  left: -25px;
}
.mountain-cap-3 {
  left: 5px;
}
.cloud,
.cloud:before,
.cloud:after {
  position: absolute;
  width: 70px;
  height: 30px;
  background: #fff;
  -webkit-border-radius: 100px / 50px;
  border-radius: 100px / 50px;
}
.cloud {
  bottom: 100px;
  -webkit-animation: cloud 50s infinite linear;
  animation: cloud 50s infinite linear;
}
@-webkit-keyframes cloud {
  0% {
    left: -100px;
  }
  100% {
    left: 1000px;
  }
}
@keyframes cloud {
  0% {
    left: -100px;
  }
  100% {
    left: 1000px;
  }
}
.cloud:before {
  content: "";
  left: 50px;
}
.cloud:after {
  content: "";
  left: 25px;
  top: -10px;
}

.sun {
  border-radius: 50%;
  background: orange;
  box-shadow: 0 0 10px orange, 0 0 60px orange, 0 0 200px yellow, inset 0 0 80px yellow;
  position: absolute;
  bottom: -160px;
  left: 200px;
  width: 100px;
  height: 100px;
}
