// Colors
$accent-color: #003366;
$primary-color: #f2f2f2;
$text-color: #2f2f2f;
$dark-background: #3d3e47;
$dark-background-darker: #313239;

// Shadow colors
$accent-color-shadow: 210deg 100% 9%;
$primary-color-shadow: 0deg 0% 63%;

// Box shadows
@mixin shadow-elevation-low {
  box-shadow: 0.3px 0.5px 0.7px hsl(0deg 0% 63% / 0.34), 0.4px 0.8px 1px -1.2px hsl(0deg 0% 63% / 0.34), 1px 2px 2.5px -2.5px hsl(0deg 0% 63% / 0.34);
}

@mixin shadow-elevation-medium {
  box-shadow: 0.3px 0.5px 0.5px hsl(0deg 0% 63% / 0.54), 0.6px 1.2px 1.3px -1.2px hsl(0deg 0% 63% / 0.45), 1.9px 3.7px 3.9px -2.5px hsl(0deg 0% 63% / 0.36), 5px 10px 10.5px -3.7px hsl(0deg 0% 63% / 0.27);
}

@mixin shadow-elevation-high {
  box-shadow: 0.3px 0.5px 0.5px hsl(0deg 0% 63% / 0.5), 0.9px 1.8px 1.9px -0.5px hsl(0deg 0% 63% / 0.46), 1.8px 3.5px 3.7px -1.1px hsl(0deg 0% 63% / 0.42), 3.3px 6.7px 7px -1.6px hsl(0deg 0% 63% / 0.38), 6px 12px 12.6px -2.1px hsl(0deg 0% 63% / 0.34), 10.2px 20.4px 21.4px -2.7px hsl(0deg 0% 63% / 0.29), 16.4px 32.8px 34.4px -3.2px hsl(0deg 0% 63% / 0.25), 25px 50px 52.4px -3.7px hsl(0deg 0% 63% / 0.21);
}

// Cta shadows
@mixin cta-shadow-elevation-low {
  box-shadow: 0.3px 0.5px 0.7px hsl(210deg 100% 9% / 0.34), 0.4px 0.8px 1px -1.2px hsl(210deg 100% 9% / 0.34), 1px 2px 2.5px -2.5px hsl(210deg 100% 9% / 0.34);
}

@mixin cta-shadow-elevation-medium {
  box-shadow: 0.3px 0.5px 0.5px hsl(210deg 100% 9% / 0.54), 0.6px 1.2px 1.3px -1.2px hsl(210deg 100% 9% / 0.45), 1.9px 3.7px 3.9px -2.5px hsl(210deg 100% 9% / 0.36), 5px 10px 10.5px -3.7px hsl(210deg 100% 9% / 0.27);
}

@mixin cta-shadow-elevation-high {
  box-shadow: 0.3px 0.5px 0.5px hsl(210deg 100% 9% / 0.5), 0.9px 1.8px 1.9px -0.5px hsl(210deg 100% 9% / 0.46), 1.8px 3.5px 3.7px -1.1px hsl(210deg 100% 9% / 0.42), 3.3px 6.7px 7px -1.6px hsl(210deg 100% 9% / 0.38), 6px 12px 12.6px -2.1px hsl(210deg 100% 9% / 0.34), 10.2px 20.4px 21.4px -2.7px hsl(210deg 100% 9% / 0.29), 16.4px 32.8px 34.4px -3.2px hsl(210deg 100% 9% / 0.25), 25px 50px 52.4px -3.7px hsl(210deg 100% 9% / 0.21);
}

// Jul
@mixin shadow-elevation-medium-cta-christmas {
  box-shadow: 0.3px 0.5px 0.5px hsl(350deg 84% 36% / 0.54), 0.6px 1.2px 1.3px -1.2px hsl(350deg 84% 36% / 0.45), 1.9px 3.7px 3.9px -2.5px hsl(350deg 84% 36% / 0.36), 5px 10px 10.5px -3.7px hsl(350deg 84% 36% / 0.27);
}

@mixin shadow-elevation-high-cta-christmas {
  box-shadow: 0.3px 0.5px 0.5px hsl(350deg 84% 36% / 0.5), 0.9px 1.8px 1.9px -0.5px hsl(350deg 84% 36% / 0.46), 1.8px 3.5px 3.7px -1.1px hsl(350deg 84% 36% / 0.42), 3.3px 6.7px 7px -1.6px hsl(350deg 84% 36% / 0.38), 6px 12px 12.6px -2.1px hsl(350deg 84% 36% / 0.34), 10.2px 20.4px 21.4px -2.7px hsl(350deg 84% 36% / 0.29), 16.4px 32.8px 34.4px -3.2px hsl(350deg 84% 36% / 0.25), 25px 50px 52.4px -3.7px hsl(350deg 84% 36% / 0.21);
}

// Reusable classes
.setBackground {
  & > *:nth-child(even) {
    background-color: #f2f2f2;
    box-shadow: 0 0 0 100vmax #f2f2f2;
    -webkit-clip-path: inset(0 -100vmax);
    clip-path: inset(0 -100vmax);
  }
}

.setBackgroundHome {
  @media (min-width: 992px) {
    & > *:nth-child(even) {
      background-color: #f2f2f2;
      box-shadow: 0 0 0 100vmax #f2f2f2;
      -webkit-clip-path: inset(0 -100vmax);
      clip-path: inset(0 -100vmax);
      overflow: hidden;
    }
  }
}

.heading-center {
  text-align: center;
  margin: 40px auto 40px auto;

  @media (min-width: 768px) {
    margin: 50px auto;
  }
}

@media (min-width: 1400px) {
  .container-width-update {
    max-width: 900px;
  }
}

@media (min-width: 2000px) {
  .container-width-update {
    max-width: 1080px;
  }
}

// Wordpress styles
.inline-wp-styles {
  & > * {
    margin: 25px 0px;
  }

  & figure {
    text-align: center;
    padding: 10px 0px 50px 0px;
    & img {
      @include shadow-elevation-high;
      border-radius: 5px;

      @media (max-width: 1200px) {
        width: 100%;
        height: auto;
      }
    }

    & > figcaption {
      margin-top: 20px;
      // font-size: 1.2rem;
    }
  }

  & p {
    & img {
      width: 100% !important;
      height: auto;
      border-radius: 5px;
      @include shadow-elevation-high;

      &.wp-image-295 {
        height: auto;
        width: 30px !important;
        box-shadow: none;
      }
    }

    & a {
      @extend .cta;
      @extend .cta-standard;
      color: red;
      display: inline-block !important;
    }

    &.has-text-align-center {
      text-align: center !important;
    }

    &.has-background {
      padding: 10px;
    }
  }
}

#banner-position {
  top: auto;
  bottom: 10px;
  & h1 {
    font-size: 1.3rem;
  }
}
